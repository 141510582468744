import React, { useState } from 'react';
import { Button } from 'antd';

import PetsModal from '../../component/pets/modal/PetsModal';
import PetsDisplay from '../../component/pets/PetsDisplay';
import AddPet from '../../component/pets/AddPet';

function Pets() {
  const [petsRenew, setPetsRenew] = useState<Date>();

  return (
    <div className="Pets">
        <PetsDisplay renew={petsRenew}/>
        <AddPet renew={() => setPetsRenew(new Date())} setStyle={true}/>
      </div>
  );
}

export default Pets;
