import React from 'react';

import './home.css'
import { Link } from 'react-router-dom';
import { Flex, FloatButton, Typography } from 'antd';

function Home() {
  return (
    <div style={{ width: '100vw' }}>
      <Flex style={{ flexDirection: 'column', width: '100%' }} className='header'>
        <Flex justify='space-around' style={{ flexDirection: 'row' }} className='header-nav'>
          <Flex justify='center' style={{ flexDirection: 'column' }}>
            <Link to='/'>
              <img
                className='header-logo'
                src="assets/images/AppLogo.png"
                width={89}
                alt=""
              />
            </Link>
          </Flex>
          <Flex justify='center' style={{ flexDirection: 'column' }}>
            <Link to='/application' className='header-app-access'>
              Accéder à l'application
            </Link>
          </Flex>
        </Flex>
        <Flex justify='center' style={{ flexDirection: 'row', alignItems: 'center' }} className='header-content'>
          <Flex justify='center' className='header-content-flex-direction'>
            <Typography.Text style={{ color: 'white' }} className='header-content-text'>Your Pet's Health in Your Hands</Typography.Text>
            <img
              src="assets/images/Screenshot.png"
              loading="lazy"
              sizes="(max-width: 221px) 221px, (max-height: 472px) 472px"
              alt=""
              style={{ width: '250px', maxWidth: '250px', height: '472px', maxHeight: '472px' }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ flexDirection: 'column', width: '100%', minHeight: '577px', backgroundColor: 'white' }} gap={20} className='mission'>
        <Flex gap={15} className='mission-content'>
          <Typography.Text className='mission-content-title'>NOTRE MISSION</Typography.Text>
          <Typography.Text className='mission-content-text'>Renforcer les liens avec vos animaux en comprenant leur santé au quotidien</Typography.Text>
        </Flex>
        <Flex style={{ maxWidth: '600px', alignSelf: 'center', marginRight: '5px', marginLeft: '5px' }}>
          <img
            src="assets/images/Mission.png"
            alt=""
            sizes="63vw"
            style={{ width: '100%' }}
          />
        </Flex>
      </Flex>
      <Flex style={{ backgroundColor: 'rgb(8, 121, 250)', width: '100%', height: '1300px' }}>
        <br/>
      </Flex>
      <FloatButton.BackTop/>
    </div>
  );
}

export default Home;