import React, { useEffect, useState } from 'react';
import { getPetsFromUser } from '../../request/pets';
import { FaDog, FaCat } from "react-icons/fa";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button, Divider, Flex, Input, List, Space, Typography } from 'antd';

import './pets.css'
import { useGlobalState } from '../../state/globalState'
import InfoContainer from '../../component/infoContainer/InfoContainer';
import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { PetDrawer } from '../../component/pets/drawer/PetDrawer';

export function formatAge(date: string) {
  dayjs.extend(relativeTime)
  const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  const [day, month, year] = date.split('/');

  function calculateDifference(date1: dayjs.Dayjs, date2: dayjs.Dayjs) {
      let start = date1;
      let end = date2;

      let years = end.diff(start, 'year');
      start = start.add(years, 'years');

      let months = end.diff(start, 'month');
      start = start.add(months, 'months');

      let days = end.diff(start, 'day');

      return { years, months, days };
  }
  const diff = calculateDifference(dayjs(`${month}-${day}-${year}`).year(Number(year)), dayjs());

  const age = `${diff.years < 1 ? '' : `${diff.years} ${diff.years === 1 ? 'an' : 'ans'}`}` +
    `${diff.months > 0 ? ' et ' : ''}` +
    `${diff.months < 1 ? '' : `${diff.months} mois`}` +
    `${diff.years > 0 || diff.months > 0 ? '' : `${diff.days} ${diff.days === 1 ? 'jour' : 'jours'}`}`;

  if (diff.years < 0 || diff.months < 0 || diff.days < 0) {
    return `Naitra le ${day} ${months[Number(month) - 1]} ${year}`;
  }
  return `Age: ${age}, née le ${day} ${months[Number(month) - 1]} ${year}`;
}

function PetsDisplay(props: any) {
  const [userPet, setUserPet] = useGlobalState('userPets');
  const [pets, setPets] = useState([""]);

  const [openVet, setOpenVet] = useState<boolean[]>([]);
  const [openIdentification, setOpenIdentification] = useState<boolean[]>([]);

  const [drawerPet, setDrawerPet] = useState();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

  const renewPets = async () => {
    const response = await getPetsFromUser()

    setPets(response);
    setUserPet(response);
  }

  const onDrawerClose = (reRender: boolean) => {
    setTimeout(() => {
      setOpenStateDrawer(false)
    }, 250);
    if (reRender) {
      renewPets();
    }
  }

  const openDrawer = () => {
    setOpenVet(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
    })
    setOpenIdentification(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
    })
    setOpenStateDrawer(true)
  }

  useEffect(() => {
    renewPets();
  }, [props.renew]);

  return (
    <div className="Pets">
      {pets[0] != "" && pets.map((pet: any, key: number) => (
        <InfoContainer key={key}>
          <Flex style={{ marginTop: '10px', justifyContent: 'space-between' }}>
            <Space style={{ marginLeft: '10px', fontSize: '16px' }}>
              {pet.species === 'dog' ? <FaDog/> : <FaCat/>}
              {pet.name}
            </Space>
            <Flex
              justify='center'
              style={{ flexDirection: 'row', height: '30px', width: 'auto', cursor: 'pointer', alignItems: 'center' }}
              onClick={() => {
                setDrawerPet(pet)
                openDrawer()
              }}
              gap={5}
            >
              <Typography.Text>Modifier</Typography.Text>
              <RightOutlined/>
            </Flex>
          </Flex>
          <Flex justify='center'>
              <List
                  style={{ width: '90%' }}
                  size="large"
                  dataSource={[
                      `Genre: ${pet.gender}`,
                      `Race: ${pet.breed}`,
                      formatAge(pet.birth_date),
                      `${pet.neutered == true ? 'Stérilisé' : 'Entier (non stérilisé)'}`,
                  ]}
                  renderItem={(item) => <List.Item style={{ padding: '10px' }}>{item}</List.Item>}
              />
          </Flex>
          {Object.keys(pet.vet).length > 0 &&
            <Flex justify='center'>
              <Flex
                onClick={() =>
                  setOpenVet(prevState => {
                    const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = prevState[index];
                      return acc;
                    }, {});
                    newState[key] = !openVet[key];
                    return newState;
                  })
                }
                style={{ marginTop: '5px', flexDirection: 'column', backgroundColor: 'rgba(197, 194, 194, 0.3)', width: '95%', borderRadius: '10px', padding: '5px', marginBottom: '10px' }}
              >
                <Flex style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography.Text
                    style={{ marginLeft: '10px'}}
                    >
                    {pet.vet.vet_name} {pet.vet.vet_name && pet.vet.vet_clinic ? '-' : ''} {pet.vet.vet_clinic}
                  </Typography.Text>
                  {openVet[key] ? (
                    <UpOutlined style={{ marginRight: '10px'}}/>
                  ) : (
                    <DownOutlined style={{ marginRight: '10px'}}/>
                  )}
                </Flex>
                <div
                  style={{
                    maxHeight: openVet[key] ? '300px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.5s ease',
                  }}
                >
                  <Divider style={{ margin: 0, marginTop: '5px' }}/>
                  <Flex justify='center' wrap style={{ marginTop: '10px', marginBottom: '10px', gap: '10px'}}>
                    <Input
                      className='vet-info'
                      addonBefore={'Email'}
                      value={pet.vet.vet_email}
                      style={{ width: '90%' }}
                      disabled
                      />
                    <Input
                      className='vet-info'
                      addonBefore={'Numéro'}
                      value={pet.vet.vet_phone_number}
                      style={{ width: '90%' }}
                      disabled
                    />
                  </Flex>
                </div>
              </Flex>
            </Flex>
          }
          <Flex justify='center'>
            <Flex
              onClick={() =>
                setOpenIdentification(prevState => {
                  const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = prevState[index];
                      return acc;
                  }, {});
                  newState[key] = !openIdentification[key];
                  return newState;
                })
              }
              style={{ marginTop: '5px', flexDirection: 'column', backgroundColor: 'rgba(197, 194, 194, 0.3)', width: '95%', borderRadius: '10px', padding: '5px' }}
            >
              <Flex style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography.Text
                  style={{ marginLeft: '10px'}}
                >
                  Détails d'identification
                </Typography.Text>
                {openIdentification[key] ? (
                  <UpOutlined style={{ marginRight: '10px'}}/>
                  ) : (
                  <DownOutlined style={{ marginRight: '10px'}}/>
                )}
              </Flex>
              <div
                style={{
                  maxHeight: openIdentification[key] ? '300px' : '0',
                  overflow: 'hidden',
                  transition: 'max-height 0.5s ease',
                }}
              >
                <Divider style={{ margin: 0, marginTop: '5px' }}/>
                <Flex justify='center' wrap style={{ marginTop: '10px', marginBottom: '10px', gap: '10px' }}>
                      <Input
                          className='vet-info'
                          addonBefore={'ID'}
                          value={pet.pet_id_number}
                          style={{ width: '90%' }}
                          disabled
                      />
                      <Input
                          className='vet-info'
                          addonBefore={'Passeport'}
                          value={pet.passport_number}
                          style={{ width: '90%' }}
                          disabled
                      />
                </Flex>
              </div>
            </Flex>
          </Flex>
          <Divider/>
          <Input.TextArea
            style={{ backgroundColor: 'rgba(197, 194, 194, 0.5)', borderRadius: '5px', color: 'black', cursor: 'default', marginTop: '5px', marginBottom: '10px' }}
            autoSize={{ minRows: 4 }}
            disabled
            value={pet.medical_history}
          >
          </Input.TextArea>
        </InfoContainer>
      ))}
      {openStateDrawer &&
        <PetDrawer onClose={onDrawerClose} pet={drawerPet} renew={renewPets}/>
      }
    </div>
  );
}

export default PetsDisplay;
