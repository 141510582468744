import { Button, Card, Divider, Flex, Form, Grid, Input, Layout, Modal, Space, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import './profile.css'
import { PlusOutlined } from '@ant-design/icons';
import { createVet } from '../../request/vet';
import { customTheme } from '../../config/theme';

function AddVets(props: any) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [showModal, setShowModal] = useState<boolean>(false)

    const [vetClinic, setVetClinic] = useState<string>("");
    const [vetName, setVetName] = useState<string>("");
    const [vetEmail, setVetEmail] = useState<string>("");
    const [vetPhoneNumber, setVetPhoneNumber] = useState<string>("");

    const [errorText, setErrorText] = useState<string>("");

    const resetVet = () => {
        setVetName("")
        setVetClinic("")
        setVetEmail("")
        setVetPhoneNumber("")
    }

    const handleSubmit = async () => {
        try {
            const vet = { vet_clinic: vetClinic, vet_name: vetName, vet_email: vetEmail, vet_phone_number: vetPhoneNumber };
            const result = await createVet(vet);

            if (result && result?.status === 201 && result?.data.msg === 'Vet added') {
                success();
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to add vet') {
                error();
            }
        } catch (error : any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setShowModal(false);
        if (props.renew) {
            props.renew();
        }
        resetVet();
    }

    const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Vétérinaire ajouté',
        });
    };

    const error = () => {
        messageApi.open({
          type: 'error',
          content: `Erreur durant l'ajout`,
        });
    };

    const validateFields = () => {
        form.validateFields(['vetName', 'vetClinic'])
            .then(() => setErrorText(''))
            .catch(() => setErrorText('Remplissez au moins un des champs ci-dessus'));
    };

  return (
    <>
        {contextHolder}
        <Layout>
            <Layout.Content style={{ display: 'flex', backgroundColor: customTheme.colorBackground }}>
                <Flex style={{ flexDirection: 'column', width: '100%'}}>
                    {showModal &&
                        <Modal
                            open={true}
                            height={'auto'}
                            title="Ajouter un animal"
                            okText="Ajouter"
                            cancelText="Annuler"
                            onCancel={() => {
                                setShowModal(false)
                                form.resetFields()
                                setVetName("");
                                setVetClinic("");
                                setVetPhoneNumber("");
                                setVetEmail("");
                                setErrorText('')
                            }}
                            onOk={() => {
                                    form.submit();
                                }
                            }
                            destroyOnClose
                            modalRender={(dom) => (
                                <Form
                                    layout="vertical"
                                    style={{ maxWidth: 600 }}
                                    form={form}
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={handleSubmit}
                                    variant='filled'
                                    clearOnDestroy
                                >
                                    {dom}
                                </Form>
                            )}
                        >
                            <Flex style={{ flexDirection: 'column' }}>
                                <Flex className='profile-container-responsive' style={{ flexDirection: 'row' }} gap={'4%'}>
                                    <Form.Item
                                        label="Nom"
                                        name="vetName"
                                        className='profile-content-responsive'
                                        style={{ width: '49%' }}
                                        rules={[
                                            {
                                                validator: async () => {
                                                    if (!vetName && !vetClinic) {
                                                        setErrorText('Remplissez au moins un des champs ci-dessus')
                                                        throw new Error();
                                                    }
                                                },
                                            }
                                        ]}
                                    >
                                        <Input
                                            addonBefore={`Dr.`}
                                            placeholder='Entrer un nom'
                                            onChange={(e) => {
                                                setVetName(e.target.value)
                                                validateFields()
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Clinique"
                                        name="vetClinic"
                                        className='profile-content-responsive'
                                        style={{ width: '49%' }}
                                        rules={[
                                            {
                                                validator: async () => {
                                                    if (!vetName && !vetClinic) {
                                                        setErrorText('Remplissez au moins un des champs ci-dessus')
                                                        throw new Error();
                                                    }
                                                },
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder='Entrer une clinique'
                                            onChange={(e) => {
                                                setVetClinic(e.target.value)
                                                validateFields()
                                            }}
                                        />
                                    </Form.Item>
                                </Flex>
                                <Typography.Text style={{ color: 'red', fontWeight: 'lighter', marginTop: '-15px', marginBottom: '10px' }}>{errorText}</Typography.Text>
                            </Flex>
                            <Flex className='profile-container-responsive' style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Item
                                    label="Email"
                                    name="vetEmail"
                                    className='profile-content-responsive'
                                    style={{ width: '48%' }}
                                >
                                    <Input placeholder='Entrer un nom' onChange={(e) => setVetEmail(e.target.value)}/>
                                </Form.Item>
                                <div style={{ width: '4%' }}/>
                                <Form.Item
                                    label="Numéro"
                                    name="vetPhoneNumber"
                                    className='profile-content-responsive'
                                    style={{ width: '48%' }}
                                >
                                    <Input placeholder='Entrer une clinique' onChange={(e) => setVetPhoneNumber(e.target.value)}/>
                                </Form.Item>
                            </Flex>
                        </Modal>
                    }
                    {!showModal &&
                        <Flex style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'transparent' }}>
                            <Button
                                type="dashed"
                                onClick={() => setShowModal(true)}
                                style={{ width: '60%' }}
                                icon={<PlusOutlined />}
                            >
                                Ajouter un vétérinaire
                            </Button>
                        </Flex>
                    }
                </Flex>
            </Layout.Content>
        </Layout>
    </>
  );
}

export default AddVets;
