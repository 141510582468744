import React, { useEffect, useState } from 'react';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import { Flex, Layout } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';

import './App.css';
import Login from './pages/login/login';
import Register from './pages/register/register';
import NavBar from './component/navBar/NavBar';
import Application from './pages/application/application';
import Pets from './pages//pets/pets'
import Profile from './pages/profile/profile'
import Calendar from './pages/calendar/calendar'
import SideBar from './component/sideBar/SideBar';
import HealthRecord from './pages/healthRecord/healthRecord';
import Home from './pages/home/home';
import LoadingScreen from './component/LoadingScreen/LoadingScreen';

import { useGlobalState } from './state/globalState'
import { verifyLogin } from './request/auth';
import { customTheme } from './config/theme';
import { WindowOutlined } from '@mui/icons-material';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useGlobalState('isLogin');
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

  const [previousLocation, setPreviousLocation] = useState(location.pathname);

  const pathToExclude: string[] = ["/login", "/register", "/", "/home"];

  const checkLogin = async () => {
    let res = true;

    try {
      res = await verifyLogin();
      setIsLogin(true)
    } catch (error) {
      res = false;
      setIsLogin(false)
    }
    if (!res) {
      navigate("/login");
      setTimeout(() => {
        setIsLoading(false)
      }, 1500);
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 1500);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 930) {
      setFooterVisibility(true)
    } else if (window.innerWidth > 930) {
      setFooterVisibility(false)
    }
  })

  React.useEffect(() => {
    if (!pathToExclude.includes(location.pathname)) {
      if (pathToExclude.includes(previousLocation, 2)) {
        setIsLoading(true)
      }
      checkLogin();
    }
    setPreviousLocation(location.pathname);
    const handleResize = () => {
      if (window.innerWidth < 930) {
        setFooterVisibility(true)
      } else if (window.innerWidth > 930) {
        setFooterVisibility(false)
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate]);

  return (
    <>
      {isLoading
        ? <LoadingScreen />
        : <>
            {!pathToExclude.includes(location.pathname)
              ? <Layout style={{ width: '100dvw' }}>
                    <Header style={{
                        width: '100vw', height: '80px',
                        position: 'sticky', top: 0, zIndex: 1, padding: 0,
                      }}
                    >
                        <NavBar/>
                    </Header>
                  <Layout>
                    {footerVisibility == false &&
                      <Sider width={'250px'}>
                        <SideBar mode='inline' display='sider'/>
                      </Sider>
                    }
                    <Content style={{
                        backgroundColor: customTheme.colorBackground,
                        height: `calc(100dvh - ${footerVisibility ? '140px' : '80px'})`,
                        overflow: 'hidden', overflowY: 'scroll'
                      }}
                    >
                      <Routes>
                        <Route path="/application" element={<Application/>}></Route>
                        <Route path="/profile" element={<Profile/>}></Route>
                        <Route path="/pets" element={<Pets/>}></Route>
                        <Route path="/health" element={<HealthRecord/>}></Route>
                        <Route path="/calendar" element={<Calendar/>}></Route>
                      </Routes>
                    </Content>
                  </Layout>
                  {footerVisibility == true &&
                    <Footer style={{
                        width: '100vw', height: '60px', position: 'fixed',
                        padding: 0, bottom: 0, zIndex: 999, backgroundColor: 'white'
                      }}
                    >
                      <Flex justify='center' style={{ height: '100%', alignItems: 'center', borderTop: '1px solid rgb(236, 236, 236)' }}>
                        <SideBar mode='horizontal' display='footer'/>
                      </Flex>
                    </Footer>
                  }
                </Layout>
              : <Routes>
                  <Route path="/login" element={<Login/>}></Route>
                  <Route path="/register" element={<Register/>}></Route>
                  <Route path="/home" element={<Home/>}></Route>
                  <Route path="/" element={<Home/>}></Route>
                </Routes>
            }
          </>
      }
    </>
  );
}

export default App;

