import React from 'react';
import { Flex, type MenuProps } from 'antd';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ReactComponent as AccueilIcon } from "../../assets/icons/Accueil.svg";
import { ReactComponent as HealthRecordIcon } from "../../assets/icons/CarnetSante.svg";
import { ReactComponent as PetProfileIcon } from "../../assets/icons/ProfilAnimal.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/icons/ProfilUtilisateur.svg";

export const SideBarData = [
    {
        key: '/application',
        label: "Accueil",
        icon: (color: string, scale: number) =>
            <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                <AccueilIcon style={{ color: color }}/>
            </Flex>
    },
    {
        key: '/calendar',
        label: "Mon Calendrier",
        icon: (color: string, scale: number, margin?: boolean) =>
            <Flex justify='center' style={{ alignItems: 'center', height: '18px', width: '18px', marginRight: margin ? '2px' : '0', transform: `scale(${scale})` }}>
                <CalendarMonthIcon style={{ color: color, height: '100%', width: '100%' }}/>
            </Flex>
    },
    {
        key: '/health',
        label: "Carnet de santé",
        icon: (color: string, scale: number) =>
            <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                <HealthRecordIcon style={{ color: color }}/>
            </Flex>
    },
    {
        key: '/pets',
        label: "Mes Compagnons",
        icon: (color: string, scale: number) =>
            <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                <PetProfileIcon style={{ color: color }}/>
            </Flex>
    },
    {
        key: '/profile',
        label: "Mon Profil",
        icon: (color: string, scale: number) =>
            <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(${scale})` }}>
                <UserProfileIcon style={{ color: color }}/>
            </Flex>
    },
]