import axios from 'axios';

const getBehaviorToday = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/behavior/today`,
        {
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
    }
}

const createBehavior = async (behaviors: string[], other: string) => {
  try {
      const data = JSON.stringify({behaviors, other}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/behavior `,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

const modifyBehaviorToday = async (behaviors: string[], other: string) => {
  try {
      const data = JSON.stringify({behaviors, other}, null, 1)
      const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/behavior/today `,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

export {
  getBehaviorToday,
  createBehavior,
  modifyBehaviorToday
}