import React, { useState } from "react"
import { Form, Input, Modal, Select } from "antd";
import dayjs from 'dayjs';

import { TypeDataAppointment } from './typeData';
import { createAppointment } from "../../../request/events";
import { dateFormat } from "../../../config/format";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import CustomTimePicker from "../../customPicker/CustomTimePicker";

const { TextArea } = Input;

function Appointment(props: any) {
    const [form] = Form.useForm();
    const [pet, setPet] = useState<any>();

    const [type, setType] = useState("");
    const [otherType, setOtherType] = useState("");

    const [date, setDate] = useState("");
    const [time, setTime] = useState("");

    const [motive, setMotive] = useState("");
    const [notes, setNotes] = useState("");

    const handleSubmit = async (event: any) => {
        const formattedDate = dayjs(date).format(`${dateFormat}`);
        const formattedTime = dayjs(time).format('HH:mm');

        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        let newType = type;

        if (type === "Autre") {
            newType = otherType;
        }

        try {
            await createAppointment({ pet_id: pet._id }, newType, formattedDateTime, motive, notes)
        } catch (error: any) {
            console.log(error.response.status)
        }
        props.onClose(true);
    }

    return (
        <Modal
            open={true}
            height={'auto'}
            title="Ajouter un rendez-vous"
            okText="Ajouter"
            cancelText="Annuler"
            onCancel={() => props.onClose(false)}
            onOk={() => {
                    form.submit();
                }
            }
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="vertical"
                    style={{ maxWidth: 600 }}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                    variant='filled'
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Form.Item
                label="Animal"
                name="pet"
                rules={[{ required: true, message: 'Sélectionner un animal' }]}
            >
                <Select placeholder='Sélectionner un animal' onSelect={(e, object) => setPet(props.pets[object.key])}>
                    {props.pets[0] !== ""  && props.pets.map((val: any, key: any) => {
                        return (
                            <Select.Option key={key} value={val.name}>{val.name}</Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: 'Sélectionner un type' }]}
            >
                <Select placeholder='Sélectionner un type' onSelect={(e) => setType(e)}>
                    {TypeDataAppointment.map((val: any, key: any) => {
                        return (
                            <Select.Option key={key} value={val.label}>{val.label}</Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            {type === "Autre" ? (
                <Form.Item
                    label="Autre type"
                    name="newType"
                    rules={[{ required: true, message: 'Entrer un autre type' }]}
                >
                    <Input placeholder='Entrer un autre type' onChange={(e) => setOtherType(e.target.value)}/>
                </Form.Item>
            ) : (
                null
            )}
            <Form.Item
                label="Motif"
                name="motive"
            >
                <TextArea rows={1} placeholder='Motif' onChange={(e) => setMotive(e.target.value)} maxLength={300}/>
            </Form.Item>
            <Form.Item
                name="date_time"
            >
                <Form.Item
                    label="Date"
                    name="date"
                    style={{ display: 'inline-block', width: '48%', marginRight: '4%'}}
                    rules={[{ required: true, message: 'Entrer une date' }]}
                >
                    <CustomDatePicker placeholder='Entrer une date' style={{ width: '100%' }} onChange={(e: any) => setDate(e as any)} format={`${dateFormat}`}/>
                </Form.Item>
                <Form.Item
                    label="Heure"
                    name="time"
                    style={{ display: 'inline-block', width: '48%' }}
                    rules={[{ required: true, message: 'Entrer une heure' }]}
                >
                    <CustomTimePicker
                        inputReadOnly={true}
                        placement={"topRight"}
                        placeholder='Entrer une heure'
                        style={{ width: '100%' }}
                        onChange={(e: any) => setTime(e as any)}
                        format={'HH:mm'}
                    />
                </Form.Item>
            </Form.Item>
            <Form.Item
                label="Informations complémentaires"
                name="complementary"
            >
                <TextArea rows={4} placeholder='Informations complémentaires' onChange={(e) => setNotes(e.target.value)} maxLength={300}/>
            </Form.Item>
        </Modal>
    );
}

export {Appointment}