import React, { useEffect, useState } from 'react';
import { Card, Divider, Flex, Typography } from 'antd';
import { FaCat, FaDog } from 'react-icons/fa';

import { useGlobalState } from '../../../state/globalState';
import { getEventsFromUser } from '../../../request/events';
import { EventDrawerAppointment } from '../drawer/AppointmentDrawer';
import { EventDrawerTreatment } from '../drawer/TreatmentDrawer';
import { customTheme } from '../../../config/theme';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { isImportant } from '../CalendarDisplay';

function CommingEvents() {
  const [reRender, setReRender] = useGlobalState('reRender');

  const [commingEvent, setCommingEvent] = useState([]);
  const [drawerEvent, setDrawerEvent] = useState();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

  const onDrawerClose = (reRender: boolean) => {
    setTimeout(() => {
      setOpenStateDrawer(false)
    }, 250);
    if (reRender) {
      getCommingEvent();
    }
  }

  const getCommingEvent = async () => {
    const response = await getEventsFromUser()

    const formatDate = (dateToFormat: string) => {
      const [date, time] = dateToFormat.split(' ');
      const [day, month, year] = date.split('-');

      return `${month}-${day}-${year} ${time || ""}`;
    }

    response.sort((a: any, b: any) => new Date(formatDate(a.date)).getTime() - new Date(formatDate(b.date)).getTime());
    let filteredResponse = response.filter((event: any) => new Date(formatDate(event.date || "")).getTime() >= new Date().getTime());

    filteredResponse = filteredResponse.map((event: any) => {
      event.important = isImportant(event);
      return event;
    });

    setCommingEvent(filteredResponse);
  }

  useEffect(() => {
    getCommingEvent()
  }, [reRender]);

  return (
    <>
      {openStateDrawer &&
          <>
              {drawerEvent && (drawerEvent as any)?.type === 'A' ? (
                  <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={getCommingEvent}/>
              ) : (
                  <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={getCommingEvent}/>
              )}
          </>
      }
      {commingEvent.length !== 0 && commingEvent[0] != "" &&
        <Flex style={{ margin: '0 auto', maxWidth: '620px', marginTop: '10px', flexDirection: 'column' }}>
          <Typography.Title level={5} style={{ textAlign: 'center', margin: 0 }}>Vos événements à venir</Typography.Title>
          {commingEvent.slice(0, 3).map((event: any, key: any) => (
            <Card
                style={{
                  height: 'auto', cursor: 'pointer', minWidth: '100px', textAlign: 'left', margin: '10px', marginRight: '15px', marginLeft: '15px',
                  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                }}
                onClick={() => {
                    setDrawerEvent(event);
                    setOpenStateDrawer(true)
                }}
                hoverable
            >
              <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                {event?.pet?.species && event?.pet?.species === 'dog' ? (
                    <FaDog style={{ fontSize: '18px' }}/>
                ) : (
                    <FaCat style={{ fontSize: '18px' }}/>
                )}
                <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                <Typography.Text style={{ fontSize: '17px' }}>{(event?.date).split(" ")[0]} - {event?.name}</Typography.Text>
                {event.important && (
                  <ExclamationCircleOutlined
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}
                  />
                )}
              </Flex>
            </Card>
          ))}
          <Flex style={{ marginTop: '25px', marginBottom: '15px', width: '50%', alignSelf: 'center', height: '2px' }}>
            <Divider style={{ margin: 0, backgroundColor: 'rgba(173, 174, 175, 0.2)' }}/>
          </Flex>
        </Flex>
      }
    </>
  );
}

export default CommingEvents;
