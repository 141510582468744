import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import './register.css'
import { useGlobalState } from '../../state/globalState'
import { registerUser } from '../../request/auth';
import { Button, Form, Input, Typography, message } from 'antd';
import { customTheme } from '../../config/theme';

function Register() {
    const [isLogin, setIsLogin] = useGlobalState('isLogin');
    const [firstname, setFirstname] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        try {
            const response = await registerUser(firstname, username, email, password);

            if (response.status == 201) {
                setIsLogin(true);
                navigate("/application");
            }
        } catch (error: any) {
            if (error.response && error.response.status == 409 && error?.response?.data?.msg == "Account already exists") {
                message.error('Le compte existe déjà');
            } else {
                message.error('Erreur interne');
            }
            setIsLogin(false);
        }
    }

  return (
    <div className="Register" style={{ height: '100vh', backgroundColor: 'rgb(8, 121, 250)' }}>
        <div className="register-container" style={{ border: `solid 1px ${customTheme.colorContainerBorder}` }}>
            <Typography.Text style={{ fontSize: '20px' }}>Inscription</Typography.Text>
            <Form
                layout='vertical'
                name="basic"
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Form.Item
                    label="Prenom"
                    name="name"
                    rules={[{ required: true, message: 'Entrer votre prenom' }]}
                >
                    <Input
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label={`Nom d'utilisateur`}
                    name="username"
                    rules={[{ required: true, message: `Entrer votre nom d'utilisateur` }]}
                >
                    <Input
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Entrer une adresse email valide', type: 'email' }]}
                >
                    <Input
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="Mot de passe"
                    name="password"
                    rules={[
                        { required: true, message: 'Entrer votre mot de passe' },
                        {
                            pattern: /^(?=.*[A-Z])(?=.*\d).{8,}$/,
                            message: 'Le mot de passe doit contenir au moins 1 majuscule, 1 chiffre et avoir au moins 8 caractères'
                        }
                    ]}
                >
                    <Input.Password
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Button type="primary" htmlType="submit">
                        S'inscire
                    </Button>
                </Form.Item>
            </Form>
            <Typography.Text style={{ fontSize: '14px' }}>
                Vous avez un compte ?{' '}
                <Link to="/login" style={{ textDecoration: 'none', fontWeight: 'bold', color: 'rgb(243, 97, 96)' }}>Connectez-vous</Link>.
            </Typography.Text>
        </div>
    </div>
  );
}

export default Register;
