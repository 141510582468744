import { CodeSandboxCircleFilled } from '@ant-design/icons';
import axios from 'axios';
import { resolveModuleName } from 'typescript';

const createSelectedPetCookies = async (id: string, name: string) => {
    try {
        const data = JSON.stringify({id: id, name: name}, null, 1)
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/pet`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
        return [""];
    }
}

const getUserSelectedPet = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/pet`,
            {
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return error.response;
    }
}

const getUserSelectedPetAvatar = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/pet/avatar`,
            {
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return '';
    }
}

export {
    createSelectedPetCookies,
    getUserSelectedPet,
    getUserSelectedPetAvatar
}
