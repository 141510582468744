import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Form, Input, InputNumber, Radio, Segmented, Select, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { FaBookMedical, FaCat, FaDog } from "react-icons/fa";

import '../healthRecord.css'
import { getUserSelectedPet } from "../../../request/user/pet";
import { formatAge } from "../../pets/PetsDisplay";
import { customTheme } from "../../../config/theme";

function HealthCardDrawer(props: any) {
    const [open, setOpen] = useState(true);

    const [petInfo, setPetInfo] = useState<any>();

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const getPetInfo = async () => {
        setPetInfo(await getUserSelectedPet());
    }

    useEffect(() => {
        getPetInfo();
    }, []);

    return (
        <Drawer
            className="health-record-drawer"
            onClose={() => handleClose()}
            open={open}
            width={650}
            closeIcon={<LeftOutlined />}
            title={
                <>
                    <FaBookMedical style={{ color: customTheme.colorPrimary }} fontSize={30}/>
                    Carte de santé
                </>
            }
        >
            {petInfo &&
                <Flex style={{ flexDirection: 'column' }}>
                    <Flex style={{ flexDirection: 'column' }} gap={16}>
                        <Flex justify='space-between' style={{ flexDirection: 'row', marginTop: '20px' }}>
                            <Flex style={{ flexGrow: 1, flexDirection: 'column' }} gap={18}>
                                <Typography.Text style={{ fontSize: '17px' }}>Id: {petInfo.pet_id_number}</Typography.Text>
                                <Typography.Text style={{ fontSize: '17px' }}>Race: {petInfo.breed}</Typography.Text>
                                <Typography.Text style={{ fontSize: '17px' }}>Sexe: {petInfo.gender}</Typography.Text>
                            </Flex>
                            <Flex className="health-card-drawer-pet-picture" style={{ flexDirection: 'column', alignItems: 'center', marginRight: '90px' }}>
                                <Flex justify='center' style={{ border: `1px solid ${customTheme.colorPrimary}`, borderRadius: '100px', height: '100px', width: '100px', alignItems: 'center' }}>
                                    {petInfo.species === 'dog' ? (
                                        <FaDog fontSize={60}/>
                                    ) : (
                                        <FaCat fontSize={60}/>
                                    )}
                                </Flex>
                                <Typography.Text style={{  }}>{petInfo.name}</Typography.Text>
                            </Flex>
                        </Flex>
                        <Flex style={{ flexDirection: 'column' }} gap={18}>
                            <Typography.Text style={{ fontSize: '17px' }}>Age: {formatAge(petInfo.birth_date)}</Typography.Text>
                            <Typography.Text style={{ fontSize: '17px' }}>Srérilisation: {petInfo.neutered == 'true' ? 'Stérilisé' : 'Entier (non stérilisé)'}</Typography.Text>
                        </Flex>
                    </Flex>
                    <Divider/>
                    <Input.TextArea
                        style={{ backgroundColor: 'rgba(197, 194, 194, 0.5)', borderRadius: '5px', color: 'black', cursor: 'default', marginTop: '5px', marginBottom: '10px' }}
                        autoSize={{ minRows: 4 }}
                        disabled
                        value={petInfo.medical_history}
                    />
                </Flex>
            }
        </Drawer>
    );
}

export { HealthCardDrawer }
