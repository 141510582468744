import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Flex, Input, Popover, Space } from 'antd';

import './behaviors.css'
import { BehaviorData } from './behaviorData'
import InfoContainer from '../infoContainer/InfoContainer';
import { createBehavior, getBehaviorToday, modifyBehaviorToday } from '../../request/behavior';
import { useGlobalState } from '../../state/globalState';

function Behaviors() {
    const [reRender, setReRender] = useGlobalState('reRender');
    const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');

    const initialState = BehaviorData.reduce((acc: any, key: any) => {
        acc[key] = false;
        return acc;
    }, {});

    const [openState, setOpenState] = useState<{[key: number]: boolean}>({});
    const [checked, setChecked] = useState<{[key: number]: boolean}>(initialState);

    const [other, setOther] = useState("");
    const [isOtherChecked, setIsOtherChecked] = useState(false);

    const [behaviorsArray, setBehaviorsArray] = useState<string[]>([]);

    const [alreadyCreated, setAlreadyCreated] = useState(false);

    const submitOther = async () => {
        try {
            if (!alreadyCreated) {
                await createBehavior(behaviorsArray, other);
                setAlreadyCreated(true);
            } else {
                await modifyBehaviorToday(behaviorsArray, other);
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
    }

    const submitBehavior = async (label: string, toAdd: boolean) => {
        let updatedArray;

        if (behaviorsArray.some((behavior: string) => behavior.startsWith(label))) {
            updatedArray = behaviorsArray.filter((items: string) => !items.startsWith(label));
        } else if (toAdd) {
            updatedArray = [...behaviorsArray, label];
        }
        if (updatedArray) {
            try {
                if (!alreadyCreated) {
                    await createBehavior(updatedArray, other);
                    setAlreadyCreated(true);
                } else {
                    await modifyBehaviorToday(updatedArray, other);
                }
            } catch (error: any) {
                if (error.response) {
                    console.log(error.response.status)
                }
            }
            setBehaviorsArray(updatedArray);
        }
    }

    const handleOpenChange = (key: number, open: boolean) => {
        setOpenState(prevState => {
            const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                acc[index] = false;
                return acc;
            }, {});
            newState[key] = open;
            return newState;
        });
    };

    const handleClick = (key: number, val: any) => {
        if (!val.key) {
            setChecked(prevState => {
                const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                    acc[index] = checked[index];
                    return acc;
                }, {});
                newState[key] = !checked[key];
                return newState;
            });
        }
        if (val.key) {
            submitBehavior(val.key, true);
        } else {
            if (val.label === 'Autre') {
                setIsOtherChecked(!isOtherChecked);
            } else {
                submitBehavior(val.label, (val.details ? false : true));
            }
        }
    };

    const detailActive = (val: any) => {
        if (val.details) {
            return !!behaviorsArray.find(item => item.startsWith(val.label));
        } else {
            return true;
        }
    }

    const isEverythingFine = (label: string) => {
        const validLabels = ["Tout va bien", "Appétit", "Hydratation", "Energie"]

        if (behaviorsArray.includes("Tout va bien") && label !== "Tout va bien"
            && !(validLabels.includes(label) && (behaviorsArray.includes(`${label} ++`) || !!!behaviorsArray.find(item => item.startsWith(label))))) {
            return true
        }
        return false;
    }

    const getSelectedBehavior = async () => {
        const result = await getBehaviorToday();

        setAlreadyCreated(!(result == null));
        if (result && (result.behaviors)) {
            setChecked(prevState => {
                const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                    acc[index] = prevState[index];
                    return acc;
                }, {});
                BehaviorData.forEach((val, key: number) => {
                    const match = result.behaviors.some((behavior: string) =>
                    behavior.startsWith(val.label)
                    );
                    newState[key] = match;
                });
                if (result.other !== "") {
                    setIsOtherChecked(true);
                    newState[Object.keys(newState).length - 2] = true;
                }
                return newState;
            });
            setBehaviorsArray(result.behaviors || []);
            setOther(result.other || "");
        }
    }

    useEffect(() => {
        getSelectedBehavior();
    }, [reRender]);

  return (
    <ConfigProvider
        theme={{
            components: {
                Button: {
                    colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
                }
            }
        }}
    >
        <InfoContainer cardTitle={`Comment se sent ${selectedPetName} aujourd'hui ?`}>
            <div className='behaviors' style={{ textAlign: 'center' }}>
                {BehaviorData && BehaviorData.map((val, key) => {
                    return (
                        <Popover
                            key={key}
                            trigger='click'
                            content={val.details &&
                                <Flex style={{ flexDirection: 'row' }} gap={5}>
                                    {val.details.map(detail => (
                                        <Button
                                            type='dashed'
                                            key={detail.key}
                                            className={`behaviors-detail ${isEverythingFine("") && detail.label !== '++' ? "disabled" : ""}`}
                                            onClick={() => {
                                                handleClick(key, detail)
                                                handleOpenChange(key, false)
                                            }}
                                            style={{ borderColor: 'rgb(116, 113, 114)' }}
                                            disabled={isEverythingFine("") && detail.label !== '++'}
                                        >
                                            {detail.label}
                                        </Button>
                                    ))}
                                </Flex>
                            }
                            open={!val.details ? false : (checked[key] ? openState[key] : false)}
                            onOpenChange={(open) => {
                                handleOpenChange(key, open)
                            }}
                        >
                            <Button
                                type='default'
                                className={`behaviors-button
                                    ${(isEverythingFine(val.label)
                                            ? "disabled"
                                            : (checked[key] && detailActive(val)
                                                ? "active"
                                                : "")
                                    )}`
                                }
                                key={key}
                                onClick={() => handleClick(key, val)}
                                disabled={isEverythingFine(val.label)}
                            >
                                {behaviorsArray.find(item => item.startsWith(val.label)) || val.label}
                            </Button>
                        </Popover>
                    );
                })}
                {isOtherChecked &&
                    <Space.Compact style={{ marginTop: '15px', width: '100%' }}>
                        <Input
                            addonBefore={"Autre"}
                            value={other}
                            onChange={(e) => setOther(e.target.value)}
                        />
                        <Button onClick={() => submitOther()} type="primary">Enregistrer</Button>
                    </Space.Compact>
                }
            </div>
        </InfoContainer>
    </ConfigProvider>
  );
}

export default Behaviors;
