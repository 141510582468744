import React, { useEffect, useState } from 'react';
import { Form, Button, ConfigProvider, Modal, Input, Dropdown, Space, Select, Radio, InputNumber, Divider, Flex } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import { createPet } from '../../../request/pets';
import { DogBreed, CatBreed } from './breed';
import { getVetFromUser } from '../../../request/vet';
import AddVets from '../../profile/AddVets';
import { useGlobalState } from '../../../state/globalState';
import { CodepenOutlined } from '@ant-design/icons';
import { dateFormat } from '../../../config/format';
import CustomDatePicker from '../../customPicker/CustomDatePicker';


function PetsModal({onSubmit, onClose}: any) {
    const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');
    const [form] = Form.useForm();

    const [name, setName] = useState<string>("");
    const [species, setSpecies] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [breed, setBreed] = useState<string>("");
    const [neutered, setNeutered] = useState(false);
    const [birthDate, setBirthDate] = useState<dayjs.Dayjs>(dayjs());
    const [petIdNumber, setPetIdNumber] = useState<number>(0);
    const [passportNumber, setPassportNumber] = useState<string>("")
    const [medicalHistory, setMedicalHistory] = useState("");
    const [vet, setVet] = useState<{vet_name: string, vet_clinic: string, vet_email: string, vet_phone_number: string} | {}>({});
    const [breedOptions, setBreedOptions] = useState<{label: string, value: string}[]>([]);
    const [breedOpen, setBreedOpen] = useState(false);

    const [vets, setVets] = useState<{vet_name: string, vet_clinic: string, vet_email: string, vet_phone_number: string}[]>();

    const handleSubmit = async (event: any) => {
        const date = new Date(birthDate.toDate()).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        try {
            await createPet({name, species, gender, breed, neutered, date, petIdNumber, passportNumber, vet, medicalHistory});
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        onSubmit();
        setReRenderSelectPet(new Date());
    }

    const disableFutureDates = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day');
    };

    const gatherUserVets = async () => {
        setVets(await getVetFromUser());
    }

    useEffect(() => {
        gatherUserVets()
    }, [])

    return (
        <div className="PetsModal">
        <Modal
            open={true}
            height={'auto'}
            title="Ajouter un animal"
            okText="Ajouter"
            cancelText="Annuler"
            onCancel={() => onClose()}
            onOk={() => {
                    form.submit();
                }
            }
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="vertical"
                    style={{ maxWidth: 600 }}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                    variant='filled'
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Form.Item
                label="Nom"
                name="name"
                rules={[{ required: true, message: 'Entrer un nom' }]}
            >
                <Input placeholder='Entrer un nom' onChange={(e) => setName(e.target.value)}/>
            </Form.Item>
            <Form.Item
                label="Espèce"
                name="species"
                rules={[{ required: true, message: 'Sélectionner une espèce' }]}
            >
                <Select placeholder='Sélectionner une espèce' onSelect={(e) => setSpecies(e)}>
                    <Select.Option value="dog">Chien</Select.Option>
                    <Select.Option value="cat">Chat</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Genre"
                name="gender"
                rules={[{ required: true, message: 'Sélectionner un genre' }]}
            >
                <Select placeholder='Sélectionner un genre' onSelect={(e, object) => setGender((object as any).children)}>
                    <Select.Option value="male">Mâle</Select.Option>
                    <Select.Option value="female">Femelle</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="breed"
                label="Race"
                rules={[{ required: true, message: 'Sélectionner une race'}]}
            >
                <Select
                    disabled={species === ''}
                    showSearch
                    placeholder={species === '' ? "Veuillez sélectionner une espèce" : "Sélectionner une race"}
                    optionFilterProp="label"
                    options={breedOptions}
                    onChange={(e) => {
                        setBreed(e);
                        setBreedOpen(false);
                    }}
                    open={breedOpen}
                    onSearch={(value) => {
                        if (value.length >= 2) {
                            setBreedOptions(species === 'cat' ? CatBreed : DogBreed);
                            setBreedOpen(true);
                        } else {
                            setBreedOptions([]);
                            setBreedOpen(false);
                        }
                    }}
                />
            </Form.Item>
            <Form.Item
                name="neutered"
                rules={[{ required: true, message: '' }]}
            >
                <Radio.Group onChange={(e) => setNeutered(e.target.value)}>
                    <Radio value={true}>Stérilisé</Radio>
                    <Radio value={false}>Non stérilisé</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Date de naissance"
                name="birth_date"
                rules={[{ required: true, message: 'Entrer une date' }]}
            >
                <CustomDatePicker
                    disabledDate={disableFutureDates}
                    placeholder='Entrer une date'
                    style={{ width: '100%' }}
                    onChange={(e: any) => setBirthDate(e as any)}
                    format={`${dateFormat}`}
                />
            </Form.Item>
            <Form.Item
                label="Vétérinaire"
                name="vet"
            >
                <Select
                    placeholder='Sélectionner un vétérinaire'
                    onSelect={(e) => setVet(JSON.parse(e))}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AddVets renew={gatherUserVets}/>
                        </>
                    )}
                    options={vets && vets.map((val) => ({
                        label: `Dr. ${val.vet_name} ${val.vet_name != "" && val.vet_clinic != "" ? ' - ' : ''}  ${val.vet_clinic}`,
                        value: JSON.stringify({vet_name: val.vet_name, vet_clinic: val.vet_clinic, vet_email: val.vet_email, vet_phone_number: val.vet_phone_number})
                    }))}
                />
            </Form.Item>
            <Form.Item
              label="Identifiant puce"
              name="petIdNumber"
              style={{ position: 'relative', top: '100%' }}
            >
              <InputNumber
                placeholder={`Entrer l'identifiant de votre animal`}
                min={0}
                style={{ width: '100%' }}
                onChange={(e: number | any) => setPetIdNumber(e)}
              />
            </Form.Item>
            <Form.Item
              label="Numéro de passeport"
              name="petPassportNumber"
              style={{ position: 'relative', top: '100%' }}
            >
              <Input
                placeholder={`Entrer le numéro de passport`}
                style={{ width: '100%' }}
                onChange={(e: any) => setPassportNumber(e.target.value)}
              />
            </Form.Item>
            <Form.Item
                label="Historique de santé"
                name="medical_history"
            >
                <TextArea rows={2} placeholder='Historique de santé' onChange={(e) => setMedicalHistory(e.target.value)} maxLength={300}/>
            </Form.Item>
        </Modal>
    </div>
  );
}

export default PetsModal;