export const customTheme = {
  textColor: 'black',
  colorBackground: '#E6F7FF',
  colorPrimary: '#007BFF',
  colorPrimaryHover: 'rgba(0, 123, 255, 0.3)',
  colorSecondary: '#FFFFFF',
  colorContainer: '#FFFFFF',
  colorContainerBorder: '#F5F5F5',
};

// export const customTheme = {
//     textColor: 'white',
//     colorBackground: '#E6F7FF',
//     colorPrimary: 'rgb(243, 97, 96)',
//     colorPrimaryHover: 'rgba(215, 85, 84, 0.8)',
//     colorSecondary: 'rgb(32, 56, 100)',
//     colorContainer: 'rgba(197, 194, 194, 0.2)',
//     colorContainerBorder: 'rgba(197, 194, 194, 0.2)',
// };