export const BehaviorData = [
    {
        label: 'Tout va bien',
        value: ''
    },
    {
        label: 'Appétit',
        details: [
            { key: 'Appétit 0', label: '0' },
            { key: 'Appétit --', label: '--' },
            { key: 'Appétit ++', label: '++' },
        ],
        value: ''
    },
    {
        label: 'Hydratation',
        details: [
            { key: 'Hydratation 0', label: '0' },
            { key: 'Hydratation --', label: '--' },
            { key: 'Hydratation ++', label: '++' },
        ],
        value: ''
    },
    {
        label: 'Energie',
        details: [
            { key: 'Energie 0', label: '0' },
            { key: 'Energie --', label: '--' },
            { key: 'Energie ++', label: '++' },
        ],
        value: ''
    },
    {
        label: 'Irritation',
        value: ''
    },
    {
        label: 'Grattage',
        value: ''
    },
    {
        label: 'Léchage',
        value: ''
    },
    {
        label: 'Blessure',
        value: ''
    },
    {
        label: 'Oeil',
        details: [
            { key: 'Oeil Fermé', label: 'Fermé' },
            { key: 'Oeil Coulant', label: 'Coulant' },
        ],
        value: ''
    },
    {
        label: 'Respiration',
        details: [
            { key: 'Respiration Lourde', label: 'Lourde' },
            { key: 'Respiration Rapide', label: 'Rapide' },
        ],
        value: ''
    },
    {
        label: 'Toux',
        value: ''
    },
    {
        label: 'Vomissement',
        value: ''
    },
    {
        label: 'Diarrhée',
        value: ''
    },
    {
        label: 'Boitement',
        value: ''
    },
    {
        label: 'Gémissement',
        value: ''
    },
    {
        label: 'Autre',
        value: ''
    }
]
