import React, { useState } from "react"
import { Button, Drawer, Flex } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { GiMedicinePills } from "react-icons/gi";

import { customTheme } from "../../../config/theme";
import CalendarModal from "../../calendar/modal/CalendarModal";

function ReminderDrawer(props: any) {
    const [open, setOpen] = useState(true);
    const [isModalVisible, setModalVisibility] = useState(false);

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const handleModalSubmit = async (submit: boolean) => {
        setModalVisibility(false);
    }

    return (
        <>
            {isModalVisible && <CalendarModal selectedEvent={"treatment-reminder"} onSubmit={handleModalSubmit}/>}
            <Drawer
                className="health-record-drawer"
                onClose={() => handleClose()}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                title={
                    <>
                        <GiMedicinePills style={{ color: customTheme.colorPrimary }} fontSize={30}/>
                        Prévention et vaccins
                    </>
                }
                footer={
                    <Flex justify="end">
                        <Button type="primary" onClick={() => setModalVisibility(true)}>
                            Ajouter un Traitement ou un rappel
                        </Button>
                    </Flex>
                }
            >
            </Drawer>
        </>

    );
}

export { ReminderDrawer }
