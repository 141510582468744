import { Flex, Typography } from "antd";
import { FunctionOutlined, RightOutlined } from "@ant-design/icons";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, Tooltip } from "recharts";
import { useEffect, useState } from "react";
import { GoArrowDownRight, GoArrowRight, GoArrowUpRight } from "react-icons/go";

import AddWeight from "./AddWeight";
import { useGlobalState } from "../../state/globalState";
import { getWeightFromPet } from "../../request/weight";
import InfoContainer from "../infoContainer/InfoContainer";
import { WeightDrawer } from "./drawer/WeightDrawer";
import { customTheme } from "../../config/theme";
import WeightChart from "./WeightChart";

export type WeightDetails = {
    weight: number;
    created_at: string;
    pet_id: string;
};

export function TrendArrow(weight: WeightDetails[], idealWeightRange?: {min: number, max: number}) {
    const lastWeight = weight[weight.length - 1].weight;
    const beforeLastWeight = weight[weight.length - 2].weight;
    let color = 'black';

    if (idealWeightRange) {
        if ((idealWeightRange.min <= lastWeight && lastWeight <= idealWeightRange.max) ||
            (lastWeight > beforeLastWeight && lastWeight <= idealWeightRange.min) ||
            (lastWeight < beforeLastWeight && lastWeight >= idealWeightRange.max)) {
            color = 'green';
        } else {
            color = 'red';
        }
    }
    if (lastWeight > beforeLastWeight) {
        return <GoArrowUpRight style={{ padding: '0px 4px', fontSize: '24px', color: color}}/>
    } else if (lastWeight < beforeLastWeight) {
        return <GoArrowDownRight style={{ padding: '0px 4px', fontSize: '24px', color: color }}/>
    }
    return <GoArrowRight style={{ padding: '0px 4px', fontSize: '24px', color: color }}/>
}

export function LastWeight(weight: WeightDetails[] | null, idealWeightRange?: {min: number, max: number}) {
    return (
        <Flex style={{ flexDirection: 'column', marginTop: 15 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {weight === null || weight.length == 0 ? (
                    'Aucun poids renseigné'
                ) : (
                    <>
                        {(weight as any)[weight.length - 1] != null && `${(weight as any)[weight.length - 1].weight} kg`}
                    </>
                )}
                {weight != null && weight.length >= 2 && <>{TrendArrow(weight, idealWeightRange)}</>}
            </div>
            <Typography.Text style={{ fontSize: '11px', color: 'rgba(116, 113, 114, 0.8)' }}>
                {weight != null && (weight as any)[weight.length - 1] != null && (weight as any)[weight.length - 1].created_at}
            </Typography.Text>
        </Flex>
    );
}

function DisplayWeight() {
    const [reRender, setReRender] = useGlobalState('reRender');

    const [weight, setWeight] = useState<WeightDetails[] | null>([]);
    const [weightDrawer, setWeightDrawer] = useState<WeightDetails[] | null>([]);

    const [idealWeightRange, setIdealWeightRange] = useState({min: 0, max: 0})

    const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

    const renewWeight = async () => {
        let response = await getWeightFromPet()
        const formatDate = (dateToFormat: string) => {
            const [date, time] = dateToFormat.split(' ');
            const [day, month, year] = date.split('-');

            return `${month}-${day}-${year} ${time}`;
        }

        if (response != null) {
            response.sort((a: WeightDetails, b: WeightDetails) => new Date(formatDate(a.created_at)).getTime() - new Date(formatDate(b.created_at)).getTime());
            setWeightDrawer(response);
            let indexBegin = (response.length - 6 < 0) ? 0 : response.length - 6;
            response = response.slice(indexBegin, response.length);
        } else {
            setWeightDrawer(response);
        }
        setWeight(response);
    }

    const onDrawerClose = (reRender: boolean) => {
        setTimeout(() => {
          setOpenStateDrawer(false)
        }, 250);
        if (reRender) {
          renewWeight();
        }
    }

    useEffect(() => {
        renewWeight();
    }, [reRender]);

    return (
        <div>
            <InfoContainer
                cardTitle={<>{LastWeight(weight, idealWeightRange)}</>}
                extra={
                    <Flex style={{ flexDirection: 'row' }}>
                        <AddWeight onSubmit={renewWeight}/>
                        {weight != null && weight.length > 0 &&
                            <Flex
                                justify='center'
                                style={{ height: '30px', width: '30px', cursor: 'pointer' }}
                                onClick={() => {
                                    setOpenStateDrawer(true)
                                }}
                            >
                                <RightOutlined/>
                            </Flex>
                        }
                    </Flex>
                }
            >
                <WeightChart weight={weight} setWeightrange={setIdealWeightRange}/>
            </InfoContainer>
            {openStateDrawer &&
                <WeightDrawer onClose={onDrawerClose} weight={weightDrawer} renew={renewWeight}/>
            }
        </div>
    );
}

export default DisplayWeight