import React, { useState } from 'react';
import { GiMedicinePills } from "react-icons/gi";
import { FaUserMd } from "react-icons/fa";
import { FaBookMedical } from "react-icons/fa6";
import { Flex, Typography } from 'antd';

import './healthRecord.css'
import InfoContainer from '../../component/infoContainer/InfoContainer';
import { HealthCardDrawer } from './drawer/HeatlhCardDrawer';
import { VetHistoryDrawer } from './drawer/VetHistoryDrawer';
import { ReminderDrawer } from './drawer/ReminderDrawer';
import { customTheme } from '../../config/theme';

function HealthSection() {
  const [openDrawer, setOpenDrawer] = useState<boolean[]>([]);

  const sections = [
    {text: 'Carte de santé', icon: FaBookMedical},
    {text: 'Historique vétérinaire', icon: FaUserMd},
    {text: 'Prévention et vaccins', icon: GiMedicinePills}
  ];

  const onDrawerClose = () => {
    setTimeout(() => {
      setOpenDrawer(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
      })
    }, 250);
  }

  return (
    <>
      <InfoContainer>
        <Flex justify='center' className='health-record-section-container' style={{ flexDirection: 'row', height: '180px', alignItems: 'center' }} gap={40}>
          {sections.map((section: any, key: number) => (
            <Flex
              key={key}
              className='health-record-btn'
              justify='center'
              style={{ height: '90%', width: '130px',flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                setOpenDrawer(prevState => {
                  const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = false;
                      return acc;
                  }, {});
                  newState[key] = !openDrawer[key];
                  return newState;
                })
              }}
              gap={5}
            >
              <section.icon fontSize={100} className='health-record-btn-icon' style={{ color: openDrawer[key] ? customTheme.colorPrimary : 'black' }}/>
              <Typography.Text className='health-record-btn-text' style={{ width: '85px' }}>{section.text}</Typography.Text>
            </Flex>
          ))}
        </Flex>
      </InfoContainer>
      {openDrawer[0] && <HealthCardDrawer onClose={onDrawerClose}/>}
      {openDrawer[1] && <VetHistoryDrawer onClose={onDrawerClose}/>}
      {openDrawer[2] && <ReminderDrawer onClose={onDrawerClose}/>}
    </>
  );
}

export default HealthSection;
