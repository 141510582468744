import React, { useEffect, useState } from "react"
import { Button, Divider, Drawer, Flex, Form, Input, InputNumber, Radio, Select, Typography } from "antd";
import dayjs from 'dayjs';

import '../pets.css'
import { LeftOutlined } from "@ant-design/icons";
import { deletePet, updatePet } from "../../../request/pets";
import AddVets from "../../profile/AddVets";
import { getVetFromUser } from "../../../request/vet";
import { CatBreed, DogBreed } from "../modal/breed";
import DeleteConfirm from "../../confirm/DeleteConfirm";
import { useGlobalState } from "../../../state/globalState";
import { dateFormat } from "../../../config/format";
import CustomDatePicker from "../../customPicker/CustomDatePicker";

function formatDate(dateToFormat: string) {
    if (typeof(dateToFormat) == 'string') {
        const [day, month, year] = dateToFormat.split('/');

        return dayjs(`${year}-${month}-${day}`)
    }
    return dateToFormat;
}

function PetDrawer(props: any) {
    const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');

    const [open, setOpen] = useState(true);
    const [modify, setModify] = useState(false);
    const [vets, setVets] = useState<{vet_name: string, vet_clinic: string, vet_email: string, vet_phone_number: string}[]>();

    let pet = props.pet;
    const [name, setName] = useState<string>(pet.name);
    const [species, setSpecies] = useState<string>(pet.species);
    const [gender, setGender] = useState<string>(pet.gender);
    const [breed, setBreed] = useState<string>(pet.breed);
    const [birthDate, setBirthDate] = useState<dayjs.Dayjs>(formatDate(pet.birth_date));
    const [neutered, setNeutered] = useState<boolean>(pet.neutered);
    const [vet, setVet] = useState<{vet_name: string, vet_clinic: string, vet_email: string, vet_phone_number: string} | {}>(pet.vet);
    const [petIdNumber, setPetIdNumber] = useState<number>(pet.pet_id_number);
    const [passportNumber, setPassportNumber] = useState<string>(pet.passport_number)
    const [medicalHistory, setMedicalHistory] = useState<string>(pet.medical_history);

    const changePet = () => {
        const formattedDate = new Date(birthDate.toDate()).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });

        pet.name = name;
        pet.species = species;
        pet.gender = gender;
        pet.breed = breed;
        pet.birth_date = formattedDate;
        pet.neutered = neutered;
        pet.vet = vet;
        pet.pet_id_number = petIdNumber;
        pet.passport_number = passportNumber;
        pet.medical_history = medicalHistory;
    }

    const handleSubmit = async () => {
        try {
            const formattedDate = new Date(birthDate.toDate()).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            });

            await updatePet({name, species, gender, breed, neutered, date: formattedDate, petIdNumber, passportNumber, vet, medicalHistory, pet_id: pet._id})
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        setModify(false);
        if (props.renew) {
            props.renew();
        }
        changePet();
        setReRenderSelectPet(new Date());
    }

    const disableFutureDates = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day');
    }

    const handleModify = async () => {
        if (modify) {
            setName(pet.name)
            setSpecies(pet.species)
            setGender(pet.gender)
            setBreed(pet.breed)
            setBirthDate(formatDate(pet.birth_date))
            setNeutered(pet.neutered);
            setVet(pet.vet)
            setPetIdNumber(pet.pet_id_number)
            setPassportNumber(pet.passport_number)
            setMedicalHistory(pet.medical_history)

        }
        setModify(!modify);
    }

    const gatherUserVets = async () => {
        setVets(await getVetFromUser());
    }

    const handleClose = (reRender: boolean) => {
        setOpen(false)
        props.onClose(reRender);
    }

    const removePets = async () => {
        try {
            await deletePet(pet._id)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
        handleClose(true);
        setReRenderSelectPet(new Date());
    }

    useEffect(() => {
        gatherUserVets()
        setOpen(true)
    }, []);

    return (
        <>
            <Drawer
                extra={
                    <Button onClick={() => handleModify()}>
                        {!modify ? "Modifier" : "Annuler"}
                    </Button>
                }
                footer={<DeleteConfirm delete={removePets}>Supprimer</DeleteConfirm>}
                onClose={() => handleClose(false)}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
            >
                <Flex style={{ flexDirection: 'column' }} gap={'10px'}>
                    <Flex style={{ flexDirection: 'column' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>Nom :</Typography.Text>
                        <Input
                            value={name}
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                            disabled={!modify}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Flex>
                    <Flex style={{ flexDirection: 'row', width: '100%' }} gap={'2%'}>
                        <Flex style={{ flexDirection: 'column', width: '49%' }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>Espèce :</Typography.Text>
                            <Select
                                value={species}
                                placeholder='Sélectionner une espèce'
                                disabled={!modify}
                                onSelect={(e) => setSpecies(e)}
                            >
                                <Select.Option value="dog">Chien</Select.Option>
                                <Select.Option value="cat">Chat</Select.Option>
                            </Select>
                        </Flex>
                        <Flex style={{  flexDirection: 'column', width: '49%' }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>Genre :</Typography.Text>
                            <Select
                                value={gender}
                                placeholder='Sélectionner un genre'
                                disabled={!modify}
                                onSelect={(e, object) => setGender((object as any).children)}
                            >
                                <Select.Option value="male">Mâle</Select.Option>
                                <Select.Option value="female">Femelle</Select.Option>
                            </Select>
                        </Flex>
                    </Flex>
                    <Flex className='pet-container-responsive' justify='flex-end' style={{ flexDirection: 'row', width: '100%' }} gap={'2%'}>
                        <Flex className="pet-content-responsive" style={{ flexDirection: 'column', width: '49%' }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>Race :</Typography.Text>
                            <Select
                                placeholder="Sélectionner une race"
                                value={breed}
                                showSearch
                                optionFilterProp="label"
                                options={species == 'cat' ? CatBreed : DogBreed}
                                disabled={!modify}
                                onChange={(e) => setBreed(e)}
                            />
                        </Flex>
                        <Flex className="pet-content-responsive" style={{  flexDirection: 'column', width: '49%' }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>Stérilisé :</Typography.Text>
                            <Radio.Group
                                className={`pet-modification ${!modify && 'disabled'}`}
                                value={neutered}
                                onChange={(e) => setNeutered(e.target.value)}
                                style={{ marginTop: '5px', cursor: "default" }}
                                disabled={!modify}
                            >
                                <Radio style={{ color: 'black' }} value={true}>Stérilisé</Radio>
                                <Radio style={{ color: 'black' }} value={false}>Non stérilisé</Radio>
                            </Radio.Group>
                        </Flex>
                    </Flex>
                    <Flex style={{ flexDirection: 'column' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>Date de naissance :</Typography.Text>
                        <CustomDatePicker
                            disabledDate={disableFutureDates}
                            value={birthDate}
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                            onChange={(e: any) => setBirthDate(e as any)}
                            format={`${dateFormat}`}
                            disabled={!modify}
                        />
                    </Flex>
                    <Flex style={{ flexDirection: 'column' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>Vétérinaire :</Typography.Text>
                        <Select
                            placeholder='Sélectionner un vétérinaire'
                            onSelect={(e) => setVet(JSON.parse(e))}
                            value={('vet_name' in vet && 'vet_clinic' in vet) ?
                                `Dr. ${vet.vet_name} ${vet.vet_name != "" && vet.vet_clinic != "" ? ' - ' : ''}  ${vet.vet_clinic}`
                            :
                                "Aucun vétérinaire sélectionné"
                            }
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <AddVets renew={gatherUserVets}/>
                                </>
                            )}
                            options={vets && vets.map((val) => ({
                                label: `Dr. ${val.vet_name} ${val.vet_name != "" && val.vet_clinic != "" ? ' - ' : ''} ${val.vet_clinic}`,
                                value: JSON.stringify({vet_name: val.vet_name, vet_clinic: val.vet_clinic, vet_email: val.vet_email, vet_phone_number: val.vet_phone_number})
                            }))}
                            disabled={!modify}
                        />
                    </Flex>
                    <Flex className='pet-container-responsive' style={{ flexDirection: 'row', width: '100%' }} gap={'2%'}>
                        <Flex className="pet-content-responsive" style={{ flexDirection: 'column', width: '49%' }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>Identifiant puce :</Typography.Text>
                            <InputNumber
                                value={petIdNumber}
                                style={{ backgroundColor: 'white', color: 'black', cursor: 'default', width: '100%' }}
                                disabled={!modify}
                                onChange={(e: number | any) => setPetIdNumber(e)}
                            />
                        </Flex>
                        <Flex className="pet-content-responsive" style={{  flexDirection: 'column', width: '49%' }}>
                            <Typography.Text style={{ fontWeight: 'bold' }}>Numéro de passeport :</Typography.Text>
                            <Input
                                value={passportNumber}
                                style={{ backgroundColor: 'white', color: 'black', cursor: 'default', width: '100%' }}
                                disabled={!modify}
                                onChange={(e: any) => setPassportNumber(e.target.value)}
                            />
                        </Flex>
                    </Flex>
                    <Divider style={{ margin: 5 }}/>
                    <Flex style={{ flexDirection: 'column' }}>
                        <Typography.Text style={{ fontWeight: 'bold' }}>Historique de santé :</Typography.Text>
                        <Input.TextArea
                            rows={4}
                            value={medicalHistory}
                            style={{ backgroundColor: 'white', color: 'black', cursor: 'default' }}
                            autoSize={{ minRows: 3 }}
                            disabled={!modify}
                            onChange={(e) => setMedicalHistory(e.target.value)}
                        />
                    </Flex>
                </Flex>
                {modify &&
                    <Button
                        type="primary"
                        style={{ marginTop: '10px' }}
                        onClick={handleSubmit}
                    >
                        Enregistrer
                    </Button>
                }
            </Drawer>
        </>
    );
}

export { PetDrawer }