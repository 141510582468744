import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import './selectPet.css'
import { useGlobalState } from '../../state/globalState';
import { getPetsFromUser } from '../../request/pets';
import { getUserSelectedPet, createSelectedPetCookies } from '../../request/user/pet';

const calculateMaxOptionWidth = (options: any[]) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context!.font = '14px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';

  let maxWidth = 0;
  options.forEach(option => {
    const width = context!.measureText(option.label).width;
    if (width > maxWidth) {
      maxWidth = width;
    }
  });

  return maxWidth + 50;
};

async function formatPetList(userPet: any) {
  let newPetList: any = []

  if (userPet[0] !== "") {
    for (let i = 0; i < userPet.length; i++) {
      newPetList.push({label: userPet[i].name, value: userPet[i]._id, species: userPet[i].species})
    }
  }
  return newPetList;
}

function SelectPet(props: any) {
  const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');
  const [userPets, setUserPets] = useGlobalState('userPets');
  const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');
  const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');

  const [formatedPetList, setFormatedPetList] = useState([])
  const [selectedPet, setSelectedPet] = useState("");

  const changeUserSelectedPet = async (value: string, object: any) => {
    if (props.changeCookies) {
      await createSelectedPetCookies(value, object?.label || "");

      setSelectedPetName(object?.label || "");
      setSelectedPet(object?.label || "")
    } else if (!props.changeCookies) {
      setSelectedPet(object.label);
      props.onChange({name: object?.label, species: object?.species, pet_id: object?.value});
      return;
    }
    if (props.onChange) {
      props.onChange();
    }
  }

  const initSelectPet = async () => {
    const result = await getPetsFromUser();

    if (result) {
      setNumberOfPets(result.length);
    }
    setUserPets(result);
    setFormatedPetList(await formatPetList(result));
    if (props.pet) {
      setSelectedPet(props.pet);
    } else {
      const response = await getUserSelectedPet();

      if (response) {
        if (result.length >= 1 && response.status === 401 && response.data.msg === 'No pet selected') {
          changeUserSelectedPet(result[0]._id, {label: result[0]?.name});
        } else {
          setSelectedPetName(response.name);
          setSelectedPet(response.name);
        }
      }
    }
  }
  const [dynamicWidth, setDynamicWidth] = useState(120);

  useEffect(() => {
    if (formatedPetList && formatedPetList.length > 0) {
      const calculatedWidth = calculateMaxOptionWidth(formatedPetList);
      setDynamicWidth(calculatedWidth);
    }
  }, [formatedPetList]);

  useEffect(() => {
    initSelectPet()
  }, [props.pet, reRenderSelectPet]);

  return (
    <>
      {formatedPetList !== null && numberOfPets >= 1 ? (
        <>
          <Select
            value={selectedPet}
            style={{ width: props.width || dynamicWidth }}
            onChange={(value, object) => changeUserSelectedPet(value, object)}
            placeholder='Sélectionner votre animal'
            options={formatedPetList}
            className={props.className || ''}
            disabled={props.disabled || false}
          />
        </>
      ) : (
        null
      )}
    </>
  );
}

export default SelectPet;

