import { Button, Card, ConfigProvider, Divider, Flex, Form, Input, Layout, List, Space, Tabs, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import './profile.css'
import { updateUserEmail, updateUser, updateUserPassword } from '../../request/user/user';
import { getVetFromUser } from '../../request/vet';
import VetsDisplay from './VetsDisplay';
import PasswordModal from './modals/PasswordModal';
import { customTheme } from '../../config/theme';
import EmailModal from './modals/EmailModal';

function ProfileDisplay(props: any) {
    const [form] = Form.useForm();
    const [formEmail] = Form.useForm();
    const [formPassword] = Form.useForm();

    const [messageApi, contextHolder] = message.useMessage();
    const user = props.user;

    const [whichSave, setWhichSave] = useState<string>("");
    const [save, setSave] = useState<boolean>(false);

    const [firstname, setFirstname] = useState("");
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordModalVisibility, setPasswordModalVisibility] = useState(false);
    const [emailModalVisibility, setEmailModalVisibility] = useState(false);

    const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Profil modifier',
        });
    };

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Erreur durant la modification',
        });
    };

    const handleSubmitProfile = async () => {
        try {
            const result = await updateUser(
                firstname !== "" ? firstname : user.firstname,
                username !== "" ? username : user.username,
                phoneNumber !== "" ? phoneNumber : (user?.phone_number || ""));
            if (result && result?.status === 201 && result?.data.msg === 'User information modified') {
                success();
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to modify user information') {
                error();
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
    }

    const handleSubmitEmail = async () => {
        try {
            const result = await updateUserEmail(email !== "" ? email : user.email);

            if (result && result?.status === 201 && result?.data.msg === 'User information modified') {
                success();
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to modify user information') {
                error();
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
    }

    const handleSubmitPassword = async () => {
        try {
            const result = await updateUserPassword("", "");

            if (result && result?.status === 201 && result?.data.msg === 'User information modified') {
                success();
            } else if (result.response && result?.response?.status === 500 && result?.response?.data.msg === 'Failed to modify user information') {
                error();
            }
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.status)
            }
        }
    }

  return (
    <ConfigProvider
        theme={{
            components: {
                Tabs: {
                    itemSelectedColor: customTheme.colorPrimary,
                    inkBarColor: customTheme.colorPrimary,
                    itemHoverColor: 'rgba(0, 123, 255, 0.8)',
                    itemColor: 'black'
                },
            }
        }}
    >
        {contextHolder}
        <Layout>
            <Layout.Content style={{ backgroundColor: '#E6F7FF' }}>
                    <Tabs
                        centered
                        className='profile-tabs'
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: 'Profil',
                                children:
                                    <>
                                        {user &&
                                            <div style={{maxWidth: '800px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <Divider className='profil-divider'>Mon profil</Divider>
                                                <Form
                                                    form={form}
                                                    layout='vertical'
                                                    onFinish={() => handleSubmitProfile()}
                                                    style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
                                                    initialValues={{ firstname: user.firstname, username: user.username, phone_number: user.phone_number || ""}}
                                                >
                                                    <div className='profile-container-responsive' style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            name="firstname"
                                                            label='Prenom'
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                            rules={[{ required: true, message: `Le champ n'est pas valide` }]}
                                                        >
                                                            <Input
                                                                value={firstname}
                                                                onChange={(e) => {
                                                                    setFirstname(e.target.value)
                                                                    setWhichSave('');
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <div style={{ width: '4%' }}/>
                                                        <Form.Item
                                                            name="username"
                                                            label={`Nom d'utilisateur`}
                                                            className='profile-content-responsive'
                                                            style={{ width: '48%' }}
                                                            rules={[{ required: true, message: `Le champ n'est pas valide` }]}
                                                        >
                                                            <Input
                                                                value={username}
                                                                onChange={(e) => {
                                                                    setUsername(e.target.value)
                                                                    setWhichSave('');
                                                                    setSave(true)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <Form.Item
                                                        name="phone_number"
                                                        label={'Numéro de téléphone'}
                                                        className='profile-content-responsive'
                                                        style={{ width: '48%' }}
                                                    >
                                                        <Input
                                                            value={phoneNumber}
                                                            onChange={(e) => {
                                                                setPhoneNumber(e.target.value)
                                                                setWhichSave('');
                                                                setSave(true)
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <div style={{ flexGrow: 1}}/>
                                                </Form>
                                                <Divider className='profil-divider'>Informations personnelles</Divider>
                                                <Form
                                                    form={formEmail}
                                                    layout='vertical'
                                                    onFinish={() => handleSubmitEmail()}
                                                    className='profile-container-responsive'
                                                    style={{ display: 'flex', padding: '10px' }}
                                                    initialValues={{ email: user.email, password: '********' }}
                                                >
                                                    <Form.Item
                                                        name="email"
                                                        label='Email'
                                                        rules={[{ required: true, message: `Le champ n'est pas valide` }]}
                                                        className='profile-content-responsive'
                                                        style={{ width: '48%' }}
                                                    >
                                                        <Space.Compact style={{ width: '100%' }}>
                                                            <Input
                                                                value={email === "" ? user.email : email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                style={{ width: '100%', backgroundColor: 'white', color: 'black', cursor: `${whichSave !== email ? 'auto' : 'default'}` }}
                                                                disabled={true}
                                                            />
                                                            <Button style={{ width: '1%' }} onClick={() => setEmailModalVisibility(true)}><EditOutlined /></Button>
                                                        </Space.Compact>
                                                    </Form.Item>
                                                </Form>
                                                <div style={{ width: '4%' }}/>
                                                <Form
                                                    form={formPassword}
                                                    layout='vertical'
                                                    onFinish={() => handleSubmitPassword()}
                                                    className='profile-container-responsive'
                                                    style={{ display: 'flex', padding: '10px' }}
                                                    initialValues={{ email: user.email, password: '********' }}
                                                >
                                                    <Form.Item
                                                        name="password"
                                                        label='Mot de passe'
                                                        rules={[{ required: true, message: `Le champ n'est pas valide` }]}
                                                        className='profile-content-responsive'
                                                        style={{ width: '48%' }}
                                                    >
                                                        <Space.Compact style={{ width: '100%' }}>
                                                            <Input
                                                                style={{ width: '100%', backgroundColor: 'white', color: 'black', cursor: 'default' }}
                                                                defaultValue={"********"}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                disabled
                                                            />
                                                            <Button style={{ width: '1%' }} onClick={() => setPasswordModalVisibility(true)}><EditOutlined /></Button>
                                                        </Space.Compact>
                                                    </Form.Item>
                                                </Form>
                                                {passwordModalVisibility && <PasswordModal onFinish={() => setPasswordModalVisibility(false)}/>}
                                                {emailModalVisibility &&
                                                    <EmailModal onFinish={(email: string) => {
                                                        setEmailModalVisibility(false);
                                                        if (email)
                                                            setEmail(email);
                                                    }}/>
                                                }
                                                {save &&
                                                    <Button
                                                        type="primary"
                                                        style={{ fontSize: '18px', position: 'fixed', bottom: '25px', right: '25px', zIndex: '500' }}
                                                        onClick={() => {
                                                            if (whichSave === 'email') {
                                                                formEmail.submit()
                                                            } else if (whichSave === 'password') {
                                                                formPassword.submit()
                                                            } else {
                                                                form.submit();
                                                            }
                                                            setSave(false)
                                                        }}
                                                    >
                                                        Enregistrer
                                                    </Button>
                                                }
                                            </div>
                                        }
                                    </>
                            },
                            {
                                key: '2',
                                label: 'Vétérinaires',
                                children:
                                    <VetsDisplay/>
                            },
                        ]}
                    />
            </Layout.Content>
        </Layout>
    </ConfigProvider>
  );
}

export default ProfileDisplay;
