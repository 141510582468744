import React, { useState } from 'react';
import { Button } from 'antd';

import PetsModal from '../../component/pets/modal/PetsModal';
import { useGlobalState } from '../../state/globalState';

type Props = {
    renew?: () => void
    setStyle: boolean
}

function AddPet(props: Props) {
  const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

  const [isModalVisible, setModalVisibility] = useState(false);

  const handleModalSubmit = async () => {
    if (props.renew) {
        props.renew()
    }
    setModalVisibility(false)
  }

  const closeModal = () => {
    setModalVisibility(false);
  }

  return (  
    <div className="Pets">
        {isModalVisible && <PetsModal onSubmit={handleModalSubmit} onClose={closeModal}/>}
        <Button
            {...props.setStyle &&
                { style: { fontSize: '18px', position: 'fixed', bottom: `calc(${footerVisibility ? '80px' : '0px'} + 25px)`, right: '25px' } }
            }
          type='primary'
          onClick={() => setModalVisibility(true)}
        >
          Ajouter un animal
        </Button>
    </div>
  );
}

export default AddPet;
