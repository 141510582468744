import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';

import './login.css'
import { useGlobalState } from '../../state/globalState'
import { loginUser } from '../../request/auth';
import { Button, Form, Input, Typography, message } from 'antd';
import { customTheme } from '../../config/theme';

function Login() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useGlobalState('isLogin');

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async (event: any) => {
        try {
            const response = await loginUser(email, password);

            if (response && response?.status == 200 && response?.data?.msg == "User logged in") {
                setIsLogin(true);
                navigate("/application");
            }
        } catch (error: any) {
            if (error.response && (error?.response?.status == 404 && error?.response?.data?.msg == "Not found") ||
                (error?.response?.status == 400 && error?.response?.data?.msg == "Invalid password")) {
                message.error("Les informations données ne sont pas correctes")
            } else {
                message.error("Erreur interne")
            }
            setIsLogin(false);
        }
    }

  return (
    <div className="Login" style={{ height: '100vh', backgroundColor: 'rgb(8, 121, 250)' }}>
        <div className="login-container" style={{ border: `solid 1px ${customTheme.colorContainerBorder}` }}>
            <Typography.Text style={{ fontSize: '20px' }}>Connexion</Typography.Text>
            <Form
                layout='vertical'
                name="basic"
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Entrer une adresse email valide', type: 'email' }]}
                >
                    <Input
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label="Mot de passe"
                    name="password"
                    rules={[{ required: true, message: 'Entrer votre mot de passe' }]}
                >
                    <Input.Password
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Button type="primary" htmlType="submit">
                        Se connecter
                    </Button>
                </Form.Item>
            </Form>
            <Typography.Text style={{ fontSize: '14px' }}>
                Vous n’avez pas de compte ?{' '}
                <Link to="/register" style={{ textDecoration: 'none', fontWeight: 'bold', color: 'rgb(243, 97, 96)' }}>Inscrivez-vous</Link>.
            </Typography.Text>
        </div>
    </div>
  );
}

export default Login;
