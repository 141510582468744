import React, { useState } from "react"
import { Flex, Form, Input, InputNumber, Modal, Select, Space } from "antd";
import dayjs from 'dayjs';

import '../calendar.css'
import { TypeDataTreatment } from './typeData';
import { createTreatment } from "../../../request/events";
import { dateFormat } from "../../../config/format";
import CustomDatePicker from "../../customPicker/CustomDatePicker";

function Treatment(props: any) {
    const [form] = Form.useForm();
    const [pet, setPet] = useState<any>();
    const [date, setDate] = useState("");

    const [type, setType] = useState("");
    const [otherType, setOtherType] = useState("");

    const [treatmentName, setTreatmentName] = useState("");

    const [durationNumber, setDurationNumber] = useState(1);
    const [durationUnit, setDurationUnit] = useState("day");

    const [intervalNumber, setIntervalNumber] = useState(1);
    const [intervalUnit, setIntervalUnit] = useState("day");

    const handleSubmit = async (event: any) => {
        const newDate = dayjs(date).format(`${dateFormat}`);
        let newType = type;

        if (type === "Autre") {
            newType = otherType;
        }
        try {
            await createTreatment({
                pet: { pet_id: pet._id },
                name: newType,
                date: newDate,
                duration: {number: durationNumber, unit: durationUnit},
                every: {number: intervalNumber, unit: intervalUnit},
                treatment: treatmentName,
                notes: ""
            });
        } catch (error: any) {
            if (error.response) {
                console.log(error.response)
            }
        }
        props.onClose(true);
    }

    return (
        <Modal
            open={true}
            height={'auto'}
            title="Ajouter un traitement ou un rappel"
            okText="Ajouter"
            cancelText="Annuler"
            onCancel={() => props.onClose(false)}
            onOk={() => {
                    form.submit();
                }
            }
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="vertical"
                    style={{ maxWidth: 600}}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                    variant='filled'
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Form.Item
                label="Animal"
                name="pet"
                rules={[{ required: true, message: 'Sélectionner un animal' }]}
            >
                <Select placeholder='Sélectionner un animal' onSelect={(e, object) => setPet(props.pets[object.key])}>
                    {props.pets[0] !== ""  && props.pets.map((val: any, key: any) => {
                        return (
                            <Select.Option key={key} value={val.name}>{val.name}</Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: 'Sélectionner un type' }]}
            >
                <Select placeholder='Sélectionner un type' onSelect={(e) => setType(e)}>
                    {TypeDataTreatment.map((val: any, key: any) => {
                        return (
                            <Select.Option key={key} value={val.label}>{val.label}</Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            {type === "Autre" ? (
                <Form.Item
                    label="Autre type"
                    name="newType"
                    rules={[{ required: true, message: 'Entrer un autre type' }]}
                >
                    <Input placeholder='Entrer un autre type' onChange={(e) => setOtherType(e.target.value)}/>
                </Form.Item>
            ) : (
                null
            )}
            <Form.Item
                label={type === "Médicament" ? ("Nom du médicament") : ("Nom du traitement")}
                name="name"
            >
                    <Input placeholder={`Entrer un le ${type === "Médicament" ? ("nom du médicament") : ("nom du traitement")}`} onChange={(e) => setTreatmentName(e.target.value)}/>
                </Form.Item>
            <Form.Item
                label={type === "Médicament" ? ("Date de début") : ("Date de dernière prise")}
                name="date"
                rules={[{ required: true, message: 'Entrer une date' }]}
            >
                <CustomDatePicker placeholder='Entrer une date' style={{ width: '100%' }} onChange={(e: any) => setDate(e as any)} format={`${dateFormat}`}/>
            </Form.Item>
            <Form.Item
                label={type === "Médicament" ? ("Durée de traitement") : ("Durée de validité")}
            >
                <Flex style={{ flexDirection: 'row' }} gap={'4%'}>
                    <Form.Item
                        name="durationNumber"
                        style={{ width: '60%' }}
                    >
                        <InputNumber
                            className="calendar-number-input"
                            defaultValue={1} min={1}
                            onChange={(e: number | any) => setDurationNumber(e)}
                            addonBefore={"Pendant"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="durationUnit"
                        style={{ width: '40%' }}
                    >
                        <Select defaultValue="Jour(s)" placeholder='' onSelect={(e) => setDurationUnit(e)}>
                            <Select.Option value="day">Jour(s)</Select.Option>
                            <Select.Option value="week">Semaine(s)</Select.Option>
                            <Select.Option value="month">Mois</Select.Option>
                            <Select.Option value="year">Année(s)</Select.Option>
                        </Select>
                    </Form.Item>
                </Flex>
            </Form.Item>
            { type === "Médicament" || type === "Autre" ? (
                <Form.Item
                    label='Intervalle'
                >
                    <Flex style={{ flexDirection: 'row' }} gap={'4%'}>
                        <Form.Item
                            name="intervalNumber"
                            style={{ width: '60%' }}
                        >
                            <InputNumber
                                className="calendar-number-input"
                                defaultValue={1} min={1}
                                onChange={(e: number | any) => setIntervalNumber(e)}
                                addonAfter={"Fois tous les"}
                            />
                        </Form.Item>
                        <Form.Item
                            name="intervalUnit"
                            style={{ width: '40%' }}
                        >
                            <Select defaultValue="Jour(s)" placeholder='' onSelect={(e) => setIntervalUnit(e)}>
                                <Select.Option value="day">Jour(s)</Select.Option>
                                <Select.Option value="week">Semaine(s)</Select.Option>
                                <Select.Option value="month">Mois</Select.Option>
                                <Select.Option value="year">Année(s)</Select.Option>
                            </Select>
                        </Form.Item>
                    </Flex>
                </Form.Item>
            ) : (
                null
            )}
        </Modal>
    );
}

export {Treatment}