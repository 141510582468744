import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Form, Input, InputNumber, Radio, Segmented, Select, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { FaCat, FaDog, FaUserMd } from "react-icons/fa";
import { getEventsFromSelectedPet } from "../../../request/events";
import { EventDrawerAppointment } from "../../calendar/drawer/AppointmentDrawer";
import { customTheme } from "../../../config/theme";
import { isDateToday } from "../../weight/drawer/WeightDrawer";
import CalendarModal from "../../calendar/modal/CalendarModal";

function VetHistoryDrawer(props: any) {
    const [open, setOpen] = useState(true);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [isModalVisible, setModalVisibility] = useState(false);

    const [petEvents, setPetEvents] = useState<any>();
    const [drawerEvent, setDrawerEvent] = useState<any>();

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const onDrawerClose = () => {
        setTimeout(() => {
            setOpenDrawer(false);
        }, 250);
    }

    const handleModalSubmit = async (submit: boolean) => {
        setModalVisibility(false);
        if (submit) {
          getPetEvents();
        }
    }

    const getPetEvents = async() => {
        const response = await getEventsFromSelectedPet();
        const formatDate = (dateToFormat: string) => {
            const [date, time] = dateToFormat.split(' ');
            const [day, month, year] = date.split('-');

            return `${month}-${day}-${year} ${time || ""}`;
        }

        response.sort((a: any, b: any) => new Date(formatDate(a.date)).getTime() - new Date(formatDate(b.date)).getTime());
        setPetEvents(response);
    }

    useEffect(() => {
        getPetEvents();
    }, []);

    return (
        <>
            {isModalVisible && <CalendarModal selectedEvent={"appointment"} onSubmit={handleModalSubmit}/>}
            {openDrawer && <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={getPetEvents}/>}
            <Drawer
                className="health-record-drawer"
                onClose={() => handleClose()}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                title={
                    <>
                        <FaUserMd style={{ color: customTheme.colorPrimary }} fontSize={30}/>
                        Historique vétérinaire
                    </>
                }
                footer={
                    <Flex justify="end">
                        <Button type="primary" onClick={() => setModalVisibility(true)}>
                            Ajouter un Rendez-vous
                        </Button>
                    </Flex>
                }
            >
                <Flex style={{ flexDirection: 'column' }} gap={'13px'}>
                    {petEvents && petEvents.map((event: any, key: number) => (
                        <>
                            {(event.type === 'A' && event.name === 'Vétérinaire') &&
                                <Card
                                    key={key}
                                    style={{ height: 'auto', minWidth: '100px' }}
                                    hoverable
                                    onClick={() => {
                                        setDrawerEvent(event);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <Flex
                                        style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                                    >
                                        {event.pet.species === 'dog' ? (
                                            <FaDog/>
                                        ) : (
                                            <FaCat/>
                                        )}
                                        {' '}
                                        <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                        <Typography.Text className='drawer-weight-text-display' style={{ fontSize: '17px' }}>
                                            {(event.date).replaceAll('/', '-')}
                                            {isDateToday(event.date.split()[0]) ? ` (AUJOURD'HUI)` : '' } - {event.name}
                                        </Typography.Text>
                                    </Flex>
                                </Card>
                            }
                        </>
                    ))}
                </Flex>
            </Drawer>
        </>
    );
}

export { VetHistoryDrawer }
