import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './sideBar.css'
import { SideBarData } from './sideBarData'
import { ConfigProvider, Flex, Menu } from 'antd';
import { useGlobalState } from '../../state/globalState';
import { customTheme } from '../../config/theme';

type PropsSideBar = {
    mode: "inline" | "horizontal";
    display: string;
}

function SideBar(props: PropsSideBar) {
    const navigate = useNavigate();

    const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');
    const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');

    const pathToExclude: string[] = ["/", "/home", "/register", "/login"];

    const isDisabled = (key: string) => {
        return !(numberOfPets >= 1 || ["/profile", "/application"].includes(key))
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        colorPrimary: customTheme.colorPrimary,
                        darkItemColor: customTheme.textColor,
                        darkItemBg: customTheme.colorSecondary,
                        darkItemHoverBg: customTheme.colorPrimaryHover,
                        darkItemHoverColor: 'black',
                        horizontalItemHoverBg: customTheme.colorPrimaryHover,
                        darkItemDisabledColor: 'rgb(120, 119, 117, 0.4)'
                    }
                }
            }}
        >
            {!pathToExclude.includes(window.location.pathname) ? (
                <Menu
                    theme='dark'
                    mode={props.mode}
                    defaultSelectedKeys={[window.location.pathname]}
                    {...props.display == "sider"
                        ? { style : { fontSize: '18px', width: '250px', position: 'fixed', height: '100vh', zIndex: 999, borderRight: '1px solid rgb(236, 236, 236)' } }
                        : { style : { fontSize: '18px', zIndex: 999 } }
                    }
                    className={`sidebar-menu ${props.display}`}
                >
                    {SideBarData.map((value: any, key: number) => (
                        <Menu.Item
                            className='sidebar-menu-item'
                            disabled={isDisabled(value.key)}
                            key={value.key}
                            icon={
                                value.icon(
                                    value.key == window.location.pathname
                                        ? 'white'
                                        : customTheme.colorPrimary
                                          , footerVisibility ? 1.5 : 1
                                          , props.display == "sider"
                                )
                            }
                            onClick={() => {
                                if (!isDisabled(value.key)) {
                                    navigate(value.key)
                                }
                            }}
                        >
                            {value.label}
                        </Menu.Item>
                    ))}
                </Menu>
            ) : (
                null
            )}
        </ConfigProvider>
    );
}

export default SideBar;
