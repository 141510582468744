import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import axios from 'axios';

type Treatment = {
  pet: { pet_id: string },
  name: string,
  date: string,
  duration: {number: number, unit: string},
  every: {number: number, unit: string},
  treatment: string,
  notes: string
}

const getEventsFromUser = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/event/all`,
            {
              withCredentials: true
            }
        );
        return response.data;
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
        return [""];
    }
}

const getEventsFromSelectedPet = async () => {
  try {
      const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/event/`,
          {
            withCredentials: true
          }
      );
      return response.data;
  } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
  }
}

const createTreatment = async (param: Treatment) => {
  try {
    const {pet, name, date, duration, every, treatment, notes} = param
      const data = JSON.stringify({pet, name, date, duration, every, treatment, notes}, null, 1)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/event/treatment`,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
    throw error;
  }
}

const createAppointment = async (pet: { pet_id: string }, name: string, date: string, motive: string, notes: string) => {
  try {
    const data = JSON.stringify({pet, name, date, motive, notes}, null, 1)
    const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/event/appointment`,
      data,
      {
          headers: {
              "Content-Type": "application/json"
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    throw error;
  }
}

const getNotes = async (eventId: string) => {
  try {
    console.log('je suis dans les requête notes')
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/event/notes/${eventId}`,
          {
              withCredentials: true
          },
      );
    return response;
  } catch (error: any) {
    throw error;
  }
}

const updateEvent = async (pet: { pet_id: string }, date: string, treatment: string, motive: string, notes: string, eventId: string) => {
  try {
    const data = JSON.stringify({pet, date, treatment, motive, notes, eventId}, null, 1)
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/event/`,
          data,
          {
              headers: {
                  "Content-Type": "application/json"
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
    throw error;
  }
}

const validateTreatment = async (eventId: string) => {
  try {
    const data = JSON.stringify({eventId}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/event/validate`,
        data,
        {
            headers: {
              "Content-Type": "application/json"
            },
            withCredentials: true
        },
    );
  } catch (error: any) {
    throw error;
  }
}

const deleteEvent = async (id: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/event/${id}`,
        {
          withCredentials: true,
        }
      );
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
    }
  }

export {
  getEventsFromUser,
  getEventsFromSelectedPet,
  deleteEvent,
  createTreatment,
  createAppointment,
  updateEvent,
  validateTreatment
}