import React, { useState } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import { updateUserPassword } from '../../../request/user/user';

function PasswordModal(props: any) {
    const [form] = Form.useForm();

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateUserPassword(values.password, values.oldPassword);
            if (response && response?.status !== 200) {
                message.error('Erreur lors du changement du mot de passe');
            } else {
                message.success('Mot de passe changé avec succès!');
                console.log('Password changed successfully:', values.password);
            }
            form.resetFields();
            props.onFinish();
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }
    };

    const handleCancel = () => {
        props.onFinish();
    };

    return (
        <>
            <Modal
                title="Changer le mot de passe"
                open={true}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Valider"
                cancelText="Annuler"
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="changePassword"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="Ancien mot de passe"
                        name="oldPassword"
                        rules={[{ required: true, message: 'Entrer votre ancien mot de passe' }]}
                    >
                        <Input.Password placeholder="Entrez votre ancien mot de passe" />
                    </Form.Item>
                    <Form.Item
                        label="Nouveau mot de passe"
                        name="password"
                        rules={[
                            { required: true, message: 'Entrer votre nouveau mot de passe' },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*\d).{8,}$/,
                                message: 'Le mot de passe doit contenir au moins 1 majuscule, 1 chiffre et avoir au moins 8 caractères',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Entrez votre nouveau mot de passe" />
                    </Form.Item>
                    <Form.Item
                        label="Confirmer le mot de passe"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: "Confirmer votre nouveau mot de passe" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                                },
                            })
                        ]}
                    >
                        <Input.Password placeholder="Confirmer votre nouveau mot de passe" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PasswordModal;
