import React, { useState } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import { updateUserEmail } from '../../../request/user/user';

function EmailModal(props: any) {
    const [form] = Form.useForm();

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateUserEmail(values.email);
            console.log(response.status);
            console.log(response.data);
            if (response && response?.status !== 200) {
                message.error('Erreur lors du changement de l\'adresse email');
            } else {
                message.success('Email changé avec succès!');
                console.log('Email changed successfully:', values.email);
            }
            form.resetFields();
            props.onFinish(values.email);
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }
    };

    const handleCancel = () => {
        props.onFinish();
    };

    return (
        <>
            <Modal
                title="Changer l'adresse email"
                open={true}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Valider"
                cancelText="Annuler"
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="changeEmail"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="Nouvelle adresse email"
                        name="email"
                        rules={[
                            { required: true, message: "L'adresse email est invalide", type: 'email' },
                        ]}
                    >
                        <Input placeholder="Entrez votre nouvelle adresse email" />
                    </Form.Item>
                    <Form.Item
                        label="Confirmer la nouvelle adresse email"
                        name="confirmEmail"
                        dependencies={['email']}
                        rules={[
                            { required: true, message: "Confirmer votre nouvelle adresse email" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('email') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Les adresses emails ne correspondent pas !'));
                                },
                            })
                        ]}
                    >
                        <Input placeholder="Confirmer votre nouvelle adresse email" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default EmailModal;
