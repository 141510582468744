export const TypeDataTreatment = [
    {
        label: "Vaccin",
        value: "vaccine",
    },
    {
        label: "Vermifuge",
        value: "deworming",
    },
    {
        label: "Anti-parasitaire",
        value: "pest_control",
    },
    {
        label: "Médicament",
        value: "medicine",
    },
    {
        label: "Autre",
        value: "other",
    }
]

export const TypeDataAppointment = [
    {
        label: "Vétérinaire",
        value: "veterinarian",
    },
    {
        label: "Toiletteur",
        value: "groomer",
    },
    {
        label: "Ostéopathe",
        value: "osteopath",
    },
    {
        label: "Autre",
        value: "other",
    }
]